$bgImg: url('../img/blue_background_new.jpg');
$fontColor: rgba(255,255,255,.8);
$fontHoverColor: rgba(255,255,255,1);
$logoColor: rgba(48, 97, 170, 1);
$leftBgColor: rgba(48, 97, 170, 0.5);
$rightBgColor: rgba(255, 255, 255, 0.8);

#root {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .login-page {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            z-index: 0;
            background-image: $bgImg;
            background-size: cover;
            background-position-x: 20%;
            background-position-y: center;
            filter: blur(3px);
            position: fixed;
            top: 0;
            right: 0;
        }

        .ant-card {
            background-color: transparent;
            box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.5);

            .ant-card-body {
                width: 90vw;
                max-width: 1200px;
                display: flex;
                padding: 0;
                min-height: 463px;

                @media (max-width: 640px) and (orientation: portrait) {
                    flex-direction: column-reverse;
                }

                @media (max-width: 640px) and (max-height: 599px) and (orientation: landscape) {
                    flex-direction: column-reverse;
                }

                .forgotPasswordFormWrapper {
                    flex: 1 0 0%;
                    display: flex;
                    justify-content: center;
                    padding: 1em;
                    background-color: $leftBgColor;

                    @media (max-height: 450px) {
                        padding: 0.5em;
                    }

                    .login-form {
                        padding: 10em 2em;
                        width: 25em;

                        @media (max-height: 380px) {
                            padding: 0.2em 2em;
                        }

                        @media (max-height: 450px) {
                            padding: 0.5em 2em;
                        }

                        @media (max-height: 600px) {
                            padding: 10em 2em;
                        }

                        @media (max-width: 480px) and (orientation: portrait) {
                            padding: 2em 1em;
                        }

                        @media (max-width: 640px) and (orientation: portrait) {
                            padding: 4em 2em;
                            width: 100vw;
                        }

                        @media (max-width: 640px) and (max-height: 599px) and (orientation: landscape) {
                            padding: 1em 2em;
                        }

                        .ant-form-item, .ant-legacy-form-item {
                            @media (max-height: 380px) {
                                margin-bottom: 0.5em;
                            }

                            @media (max-height: 450px) {
                                margin-bottom: 1em;
                            }

                            .loginIcon {
                                width: 1.75em;
                                margin-left: -0.6em;
                            }

                            .login-form-forgot {
                                float: right;
                                color: $fontColor;

                                &:hover {
                                    color: $fontHoverColor;
                                }
                            }

                            .login-form-terms {
                                color: $fontColor;

                                &:hover {
                                    color: $fontHoverColor;
                                }
                            }

                            .login-form-button {
                                width: 100%;
                            }
                        }
                    }
                }

                .card-login-caption {
                    background: $rightBgColor;
                    flex: 1 0 0%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 1em;

                    @media (max-width: 640px) and (orientation: landscape) {
                        flex-direction: row;
                        align-items: baseline;
                    }

                    .companyLogo {
                        fill: $logoColor;
                        max-width: 400px;
                        width: auto;
                        height: 100px;

                        @media (max-width: 640px) and (orientation: landscape) {
                            min-width: 30vw;
                            min-height: 5vh;
                        }
                    }

                    h1, h2 {
                        color: #fff;
                        text-align: center;

                        @media (max-height: 450px) {
                            margin-bottom: 0.25em;
                        }
                    }

                    h1 {
                        font-style: italic;

                        @media (max-width: 640px) and (orientation: landscape) {
                            margin-right: 0.5em;
                        }
                    }
                }
            }
        }
    }
}
