@import '../../style/color.scss';

.ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
    min-width: 60px;
    width: 60px;
}

.ant-table-wrapper {
    .ant-table-thead {
        .react-resizable {
            position: relative;
            background-clip: padding-box;
        }

        .react-resizable-handle {
            cursor: col-resize;
            position: absolute;
            width: 20px;
            height: 100%;
            bottom: 0;
            right: -5px;
        }
    }

    .ant-table-tbody {
        > .ant-table-row {
            &.altRow {
                &:hover:not(.ant-table-expanded-row) > td {
                    background: #dfe8ed;
                }

                td {
                    background-color: $altRowColor;
                }
            }

            &.categoryRow {
                td {
                    background-color: $categoryRowColor;
                }
            }

            &.subCategoryRow {
                td {
                    background-color: $subCategoryRowColor;
                }
            }

            &.ant-table-row-selected, &.altRow.ant-table-row-selected {
                td {
                    background-color: $selectedRowColor;
                }
            }

            td.ant-table-row-expand-icon-cell {

                .ant-table-row-expand-icon {
                    background-image: url('../../img/arrow_down_grey.png');
                    background-size: contain;
                    background-color: transparent;
                    border: none;
                    width: 24px;
                    height: 24px;
                    transition: all 0.2s;
                    transform: rotate(-90deg);
                    margin-top: 5px;

                    &.ant-table-row-expanded, &.ant-table-row-expand-icon-expanded {
                        transform: rotate(0deg);
                    }

                    &.ant-table-row-expand-icon-expanded {
                        &:before, &:after {
                            background: none;
                        }
                    }

                    &:before, &:after {
                        content: none;
                        background: none;
                    }
                }
            }

            &.ant-table-row-hover > td {
                background: #dfe8ed;
            }
        }
    }

    .ant-table-pagination.ant-pagination {
        text-align: center;
        float: none;
        justify-content: center;
    }

    .custom-filter-dropdown {
        padding: 8px;
        border-radius: 6px;
        background: #fff;
        box-shadow: 0 1px 6px rgba(0, 0, 0, .2);

        input {
            width: 130px;
            margin-right: 8px;
        }

        .ant-input-affix-wrapper {
            width: 130px;
            margin-right: 8px;
        }
    }

    /*.ant-table-cell button {
        height: initial;
    }*/

    .ant-table.ant-table-middle .ant-table-title,
    .ant-table.ant-table-middle .ant-table-footer,
    .ant-table.ant-table-middle .ant-table-thead > tr > th,
    .ant-table.ant-table-middle .ant-table-tbody > tr > td,
    .ant-table.ant-table-middle tfoot > tr > th,
    .ant-table.ant-table-middle tfoot > tr > td {
        padding: 3px 8px;
    }

    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        width: 0px;
    }
}

