$fontColor: rgba(255,255,255,.8);
$fontHoverColor: rgba(255,255,255,1);

.terms {
    font-style: italic;
}

.terms-warning {
    //color: red;
}

.terms-modal-header {
    text-align: center;

    h1 {
        margin: 0.5em 0;
        color: #3061AA !important;
        font-family: 'RalewayBold';
    }
}

.terms-modal-content {
    text-align: center;
    font-family: 'RobotoMedium';
}