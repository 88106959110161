/* Basic color */
$grey: #fafafa;
$grey2: #292929;
$white: #fff;
$red: #f5222d;
$yellow: #fff566;
$green: #52c41a;
$orange: #faad14;
$peach: #f7c588;
$blue: #8dc8e3;

/* meaningful color */
$primaryColor: #1963ae;
$whiteAlphaColor: rgba(255, 255, 255, 0.65);
$blackAlphaColor: rgba(0, 0, 0, 0.65);
$warningColor: #faad14;
$linkWithDeepBgColor: $white;
$shadowColor: rgba(0,0,0,0.2);
$darkerShadowColor: rgba(0,0,0,0.5);

/* for disable */
$disableInputTextColor: rgba(76, 76, 76, 0.85);
$disableInputHoverBorderColor: #b3b3b3;
$disableSelectedColor: $primaryColor;

/* for menu */
$headerBarColor: $primaryColor;
$headerBarFontColor: $white;
$menuShadowColor: $shadowColor;
$menuColor: $grey;
$menuFontColor: $blackAlphaColor;
$menuHighlightedColor: #e6f7ff;
$menuHighlightedFontColor: $blackAlphaColor;

$theme2-headerBarColor: $grey;
$theme2-headerBarFontColor: $primaryColor;
$theme2-menuColor: $primaryColor;

/* for table */
$selectedRowColor: #bde3ff;
$altRowColor: $grey;
$categoryRowColor: $blue;
$subCategoryRowColor: $peach;

/* for Status Card */
$redStatusColor: $red;
$yellowStatusColor: $yellow;
$orangeStatusColor: $orange;
$greenStatusColor: $green;
$smallTextColor: $grey2;
$statusLinkTextColor: #0043c1;
$cardBackgroundColor: $grey;
$cardShadowColor: $darkerShadowColor;

/* for Notification */
$notificationShadowColor: $shadowColor;