.privacy-policy-acceptance-container {
    box-shadow: 1px -1px grey;
    background: white !important;
    color: black !important;

    .privacy-policy-acceptance-content {

        .policy {
            &:hover {
                font-weight: bold;
            }
        }
    }

    .privacy-policy-acceptance-button {
        margin: 1em 1em 1em auto !important;
        border: 3px solid #3061aa !important;
        padding: 0 4em !important;
        background: white !important;
    }
}

.privacy-policy-acceptance-modal-header {
    text-align: center;

    h1 {
        margin: 0.5em 0;
        color: #3061AA !important;
        font-family: 'RalewayBold';
    }
}

.privacy-policy-acceptance-modal-content {
    text-align: center;
    font-family: 'RobotoMedium';
}