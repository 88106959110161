@font-face {
    font-family: RobotoBlack;
    src: url('./fonts/Roboto/Roboto-Black.ttf');
}

@font-face {
    font-family: RobotoMedium;
    src: url('./fonts/Roboto/Roboto-Medium.ttf');
}

@font-face {
    font-family: RobotoThin;
    src: url('./fonts/Roboto/Roboto-Thin.ttf');
}

@font-face {
    font-family: RalewayBold;
    src: url('./fonts/raleway/Raleway-Bold.ttf');
}

@font-face {
    font-family: RalewayThin;
    src: url('./fonts/raleway/Raleway-Thin.ttf');
}

@font-face {
    font-family: RalewayMedium;
    src: url('./fonts/raleway/Raleway-Medium.ttf');
}

@font-face {
    font-family: RalewayRegular;
    src: url('./fonts/raleway/Raleway-Regular.ttf');
}

h1 {
    font-size: 2em;
}