.CustomSlider {
    display: flex;
    flex-flow: row;
    align-items: center;

    > div {
        margin-top: 28px !important;
        margin-bottom: 28px !important;
    }

    :first-child {
        margin-right: 0 !important;
    }

    .greenTrack {
        background-color: #a1c057 !important;
        width: 100%;
        position: unset !important;
        margin-right: 6px !important;
    }

    .disabledTrack {
        background-color: #b8b8b8 !important;
        width: 100%;
        position: unset !important;
        margin-right: 6px !important;
    }
}