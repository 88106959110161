@import '../style/color.scss';

.ant-menu-root {
    border-right: none;
    background-color: $menuColor;

    .ant-menu-sub.ant-menu-inline {
        background-color: $menuColor;

        > .ant-menu-item, > .ant-menu-title-content, > .ant-menu-submenu > .ant-menu-submenu-title {
            height: auto;
            white-space: normal;
            line-height: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .ant-menu-item, .ant-menu-title-content {
        > a {
            color: $menuFontColor;
        }

        &.ant-menu-item-selected {
            background-color: $menuHighlightedColor;

            > a {
                color: $menuHighlightedFontColor;
            }
        }

        &.ant-menu-item-active {
            &::after {
                opacity: 1;
                transform: scaleY(1);
            }
        }
    }

    .ant-menu-submenu {
        color: $menuFontColor;
    }
}

.darkTheme {
    .ant-menu-root {
        background-color: $primaryColor;

        .ant-menu-sub.ant-menu-inline {
            background-color: $primaryColor;
        }

        .ant-menu-item, .ant-menu-title-content {
            > a {
                color: $white;
            }

            &.ant-menu-item-selected {
                > a {
                    color: $primaryColor;
                }
            }
        }

        .ant-menu-submenu {
            color: $white;
        }

        .ant-menu-submenu-arrow:before, .ant-menu-submenu-arrow:after {
            background-image: linear-gradient(to right, $whiteAlphaColor, $whiteAlphaColor);
        }
    }
}