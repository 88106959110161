@import './style/color.scss';
@import './fonts.scss';

@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
    }
}

#root {
    font-size: 1em;

    input.ant-input-disabled,
    textarea.ant-input-disabled,
    .ant-input-number.ant-input-number-disabled,
    .ant-input-number-disabled .ant-input-number-input,
    .ant-select-auto-complete.ant-select .ant-input[disabled],
    .ant-picker-input > input[disabled] {
        border: 1px solid transparent;
        color: $disableInputTextColor;

        &:hover {
            border-color: $disableInputHoverBorderColor;
        }
    }

    .ant-select-disabled {
        color: $disableInputTextColor;

        .ant-select-selection-item, .ant-select-selection-search {
            color: $disableInputTextColor;
        }
    }

    .has-warning, .ant-form-item-has-warning {
        input.ant-input-disabled,
        textarea.ant-input-disabled,
        .ant-input-number.ant-input-number-disabled,
        .ant-input-number-disabled .ant-input-number-input,
        .ant-select-auto-complete.ant-select .ant-input[disabled],
        .ant-radio-button-wrapper, .ant-select-selector,
        .ant-picker-input > input[disabled] {
            border-color: $warningColor;

            &:hover {
                border-color: $warningColor;
            }
        }
    }


    .ant-radio-button-wrapper-disabled {
        /*color: $disableInputTextColor;*/

        &:hover {
            border-color: $disableInputHoverBorderColor;
        }

        &.ant-radio-button-wrapper-checked {
            color: #fff;
            background-color: $disableSelectedColor;
        }
    }

    .ant-select-disabled .ant-select-selection {
        user-select: text;
        -webkit-user-select: text;
    }

    .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
        color: $disableInputTextColor;
    }

    .ant-cascader-picker.ant-cascader-picker-disabled .ant-cascader-picker-label {
        color: $disableInputTextColor;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        /*font-family: RobotoBlack;*/
        font-weight: bold;
    }
    /*.ant-table-placeholder {
        position: static;
    }*/
}

.ant-tooltip.whiteTooltip {
    .ant-tooltip-arrow {
        border-top-color: #fff;
    }

    .ant-tooltip-inner {
        background-color: #fff;
    }
}

.ant-tooltip.tableTooltip {
    max-width: none;

    .ant-tooltip-inner {
        width: 80vw;
        height: auto;

        .ant-table {
            border: 1px solid #54d018;
        }
    }
}

.ant-popover.tablePopconfirm {
    .ant-table {
        width: 80vw;
    }
}

/*@supports (-ms-ime-align:auto) {
    .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
        position: static;
    }
}*/