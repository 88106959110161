@import '../style/color.scss';

$headerHeight: 64px;
$shrinkHeaderHeight: 48px;
$menuShadow: $menuShadowColor 2px 2px 1px;

.root {
    min-height: 100vh;

    .header {
        height: $headerHeight;
        background-color: $headerBarColor;
        color: $headerBarFontColor;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 1em;
        padding-right: 1em;
        transition: all 0.5s;
        box-shadow: $darkerShadowColor 0px 2px 1px;
        position: absolute;
        z-index: 100;
        width: 100%;
        /*.companyLogo {
            fill: $headerBarFontColor;
            height: calc(#{$headerHeight} - 1.5em);
            transition: all 0.5s;
        }*/
        .companyLogo {
            height: calc(#{$headerHeight} - 1.5em);
            transition: all 0.5s;

            path, polygon {
                fill: $headerBarFontColor;
            }
        }
    }

    .tooltipToRight {
        right: 1em;
        left: auto !important;
    }

    &.shrinkHeader {
        .header {
            height: $shrinkHeaderHeight;
            transition: all 0.5s;

            .companyLogo {
                height: calc(#{$headerHeight} - 2.5em);
            }
        }

        .main {
            .leftSider {
                .menuToggle {
                    top: $shrinkHeaderHeight;
                }
            }
        }
    }

    .main {
        .mainContent {
            margin-top: calc(#{$headerHeight} + 1em);
            padding: 0 1em;

            .breadcrumb {
                margin-left: 1.5em;
                margin-bottom: 1em;
            }

            .loginedUser {
                text-align: right;
                text-decoration: none;
            }
        }
        /*.hiddenMenu {
            position: absolute;
            z-index: 8;
            top: $headerHeight;
            left: 5px;
            background-color: $menuColor;
            padding: 1.2em 0.5em 0.7em 0.2em;
            border-bottom-right-radius: 0.5em;
            border-top-right-radius: 0.5em;
            transition: all .2s;
            box-shadow: $menuShadow;
            cursor: pointer;
            font-size: 1em;

            svg {
                fill: $menuFontColor;
            }
        }*/
        .menuToggle {
            position: absolute;
            z-index: 8;
            top: $headerHeight;
            left: 250px;
            background-color: $menuColor;
            padding: 1.2em 0.5em 0.7em 0.2em;
            border-bottom-right-radius: 0.5em;
            border-top-right-radius: 0.5em;
            transition: all .2s;
            box-shadow: $menuShadow;
            cursor: pointer;
            font-size: 1em;

            &.hidden {
                left: 5px;
            }

            &.fixed {
                position: fixed;
                top: 0;
            }

            svg {
                fill: $menuFontColor;
            }
        }

        .leftSider {
            position: relative;
            box-shadow: $menuShadow;
            background: $menuColor;
            padding-top: 64px;
            overflow-x: hidden;
            overflow-y: hidden;
            /*&.hidden {
                .menuToggle {
                    left: 5px;
                }
            }

            .menuToggle {
                position: fixed;
                z-index: 8;
                top: $headerHeight;
                left: 250px;
                background-color: $menuColor;
                padding: 1.2em 0.5em 0.7em 0.2em;
                border-bottom-right-radius: 0.5em;
                border-top-right-radius: 0.5em;
                transition: all .2s;
                box-shadow: $menuShadow;
                cursor: pointer;
                font-size: 1em;

                svg {
                    fill: $menuFontColor;
                }
            }

            &.ant-layout-sider-collapsed {
                .menuToggle {
                    left: 80px;
                }
            }

            &.ant-layout-sider-zero-width {
                .menuToggle {
                    left: 0;
                }
            }*/
        }

        .footer {
            text-align: center;
        }
    }

    &.darkTheme {
        .header {
            background-color: $theme2-headerBarColor;
            color: $theme2-headerBarFontColor;

            .companyLogo {
                fill: $theme2-headerBarFontColor;
            }
        }

        .main {
            .leftSider {
                background: $theme2-menuColor;
                color: $white;

                .menuToggle {
                    background-color: $primaryColor;

                    svg {
                        fill: $white;
                    }
                }
            }
        }
    }
}

.ant-table-row {
    //line-height: 15px; //reduce table row spacing

    & > td {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }

    *.ant-checkbox {
        margin: auto 0 !important;
        top: 0 !important;
    }

    *.ant-table-row-expand-icon {
        //margin-top: 0 !important; //reduce table row spacing
        height: 15px !important;
        width: 15px !important;
        border: unset !important;
    }

    &:not(.ant-table-row-selected):hover *.ant-table-row-expand-icon {
        background-color: #F9F9F9 !important;
    }

    &.ant-table-row-selected *.ant-table-row-expand-icon {
        background-color: #BDE3FE !important;
    }
}