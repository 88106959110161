@import '../../style/color.scss';

.bellBadge { 
    margin-left: 10px;
    font-size: 2em;
}

.notificationListPopUp {
    max-width: 80vw;
    min-width: 350px;
    position: absolute;
    z-index: 999;
    right: 0;
    top: 3em;
    box-shadow: $notificationShadowColor 0px 2px 1px;

    &.expandWidth {
        width: 860px; // 1920px /2
    }

    &.normalWidth {
        width: 450px;
    }
}

.notificationMasterList {
    width: 550px;
    margin: 0 auto;
}

.notificationListUnReadFilter {
    button[data-read-filter-selected="true"] {
        color: #4b9bd7;
        border-bottom: 1px solid #4b9bd7;
    }
}

.notificationList {
    max-height: 62vh;
    overflow-y: overlay;
    overflow-x: hidden;
    margin-right: -15px;
    justify-content: center;
    flex-flow: column;
    display: block;
    
    & > * {
        margin-right: -5px;
        padding-right: 15px;
    }
}

.notificationListItem {
    min-height: 60px;
    width: 100%;
    display: flex;

    &:hover {
        background-color: #cde6f7;

        & > .readBar > .readIndicator.read {
            background-color: #b1d6f0;
        }
    }

    & > .readBar {
        width: 15px;

        & > .readIndicator {
            width: 4px;
            height: 100%;
        }
        & > .readIndicator:hover {
            width: 8px;
        }
        & > .readIndicator.read:hover {
            background-color: #b1d6f0;
        }
        & > .readIndicator.unread {
            background-color: #005a9e;
        }
    }
    & > .moduleIcon > * {
        margin: auto 5px auto 0px;
    }
    & > .context {
        width: 100%;
        text-align: left;
        cursor: pointer;
        
        & > div:not(:last-child) {
            margin-bottom: 15px;
            width: 100%;
        }
    }
}

h2[data-title-visible="true"],
h2[data-searchbox-visible="true"] {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
}

h2[data-title-visible="false"],
h2[data-searchbox-visible="false"] {
    width: 0%;
    height: 32px;
    display: flex;
    align-items: center;
    visibility: hidden;
}

.slideIn {
    transition: width 0.5s;
    white-space: nowrap;
}

div[data-unread-msg="true"] {
    font-weight: bold;
}

/* scroll bar */
/* width */
.notificationList::-webkit-scrollbar {
    width: 10px;
}

/* Track */
/*.notificationList::-webkit-scrollbar-track {
    background: #f1f1f1; 
}*/

/* Handle */
.notificationList::-webkit-scrollbar-thumb {
    background: #cccccc; 
    border-radius: 8px;
    border: 1px solid transparent;
    background-clip: content-box;
}

/* Handle on hover */
/*.notificationList::-webkit-scrollbar-thumb:hover {
    background: #555; 
}*/

.notificationMessage {
    max-height: 135px;
    overflow: auto;
}
.notificationMessage::-webkit-scrollbar {
    width: 10px;
}
.notificationMessage::-webkit-scrollbar-thumb {
    background: #cccccc; 
    border-radius: 8px;
    border: 1px solid transparent;
    background-clip: content-box;
}
