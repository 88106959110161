@import '../style/color.scss';

$headerHeight: 64px;
$shrinkHeaderHeight: 48px;
$menuShadow: $menuShadowColor 2px 2px 1px;

.root {
    min-height: 100vh;

    .header {
        height: $headerHeight;
        background-color: $headerBarColor;
        color: $headerBarFontColor;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 1em;
        padding-right: 1em;
        transition: all 0.5s;
        box-shadow: $darkerShadowColor 0px 2px 1px;
        position: absolute;
        z-index: 100;
        width: 100%;

        .companyLogo {
            height: calc(#{$headerHeight} - 1.5em);
            transition: all 0.5s;

            path, polygon {
                fill: $headerBarFontColor;
            }
        }
    }

    .main {
        .mainContent {
            margin-top: calc(#{$headerHeight} + 1em);
            padding: 0 1em;
        }

        .footer {
            text-align: center;
        }
    }
}
